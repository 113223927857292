@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

html {
  height: 100%;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
}

#main-container {
  overflow:auto;
  padding-bottom:60px;
}


footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}
